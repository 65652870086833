import React from "react";
import "../Aboutus/Aboutus.css";
import Text from "../Text/Text";
import Plan from "../Plan/Plan";
import Details from "../Details/Details";
const Aboutus = () => {
  return (
    <>
      <Text />
    
      <Details/>
   
     
      <Plan/>
    </>
  );
};

export default Aboutus;
