import React, { useState } from "react";
import "../Gallery/Gallery.css";
function Gallery() {
  const [selectedImage, setSelectedImage] = useState(
    "https://img.freepik.com/premium-photo/aerial-view-district-shanghai-with-roads-high-rise-buildings_87743-221.jpg?w=826"
  );

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  return (
    <div className="full-gallery">
      <h1 className="gal-h">Our Gallery</h1>
      <div className="container1">
        {/* Row 1, Div 1 - Top-left */}
        <div className="thumbnail thumbnails2">
          <img
            className="ani-1"
            src="g1.jpg"
            alt="Image 1"
            onClick={() =>
              handleImageClick(
                "g1.jpg"
              )
            }
          />
        </div>

        {/* Row 1, Div 2 - Middle */}
        <div className="thumbnail thumbnails1">
          <img
            className="ani-1"
            src="g2.avif"
            alt="Image 2"
            onClick={() =>
              handleImageClick(
                "g2.avif"
              )
            }
          />
          <img
            className="ani-1"
            src="g3.jpg"
            alt="Image 3"
            onClick={() =>
              handleImageClick(
                "g3.jpg"
              )
            }
          />
          <img
            className="ani-1"
            src="g4.jpg"
            alt="Image 4"
            onClick={() =>
              handleImageClick(
                "g4.jpg"
              )
            }
          />
        </div>

        {/* Row 1, Div 3 - Top-right */}
        <div className="thumbnail thumbnails2">
          <img
            className="ani-1"
            src="g5.jpg"
            alt="Image 5"
            onClick={() => handleImageClick("g5.jpg")}
          />
        </div>

        {/* Row 2, Div 1 - Column down */}
        <div className="thumbnail thumbnails4">
          <img
            className="ani-1"
            src="g6.jpg"
            alt="Image 6"
            onClick={() => handleImageClick("g6.jpg")}
          />
          <img
            className="ani-1"
            src="g7.jpg"
            alt="Image 7"
            onClick={() => handleImageClick("g7.jpg")}
          />
          <img
            className="ani-1"
            src="g8.jpg"
            alt="Image 8"
            onClick={() => handleImageClick("g8.jpg")}
          />
        </div>

        {/* Large Thumbnail Display */}
        <div className="thumbnail" id="large-thumbnail">
          <img className="ani-2" src={selectedImage} alt="Large Thumbnail" />
        </div>

        {/* Row 2, Div 3 - Top-right */}
        <div className="thumbnail thumbnails4">
          <img
            className="ani-1"
            src="g9.jpg"
            alt="Image 9"
            onClick={() => handleImageClick("g9.jpg")}
          />
          <img
            className="ani-1"
            src="g10.jpg"
            alt="Image 10"
            onClick={() => handleImageClick("g10.jpg")}
          />
          <img
            className="ani-1"
            src="g10.avif"
            alt="Image 11"
            onClick={() => handleImageClick("g10.avif")}
          />
        </div>

        {/* Row 3, Div 1 - Bottom-left */}
        <div className="thumbnail thumbnails2">
          <img
            className="ani-1"
            src="g10.png"
            alt="Image 12"
            onClick={() => handleImageClick("g10.png")}
          />
        </div>

        {/* Row 3, Div 2 - Middle */}
        <div className="thumbnail thumbnails1">
          <img
            className="ani-1"
            src="g13.avif"
            alt="Image 13"
            onClick={() => handleImageClick("g13.avif")}
          />
          <img
            className="ani-1"
            src="g14.jpg"
            alt="Image 14"
            onClick={() => handleImageClick("g14.jpg")}
          />
          <img
            className="ani-1"
            src="g16.jpg"
            alt="Image 15"
            onClick={() => handleImageClick("g16.jpg")}
          />
        </div>

        {/* Row 3, Div 3 - Bottom-right */}
        <div className="thumbnail thumbnails2">
          <img
            className="ani-1"
            src="g17.jpg"
            alt="Image 16"
            onClick={() => handleImageClick("g17.jpg")}
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
