import React, { useState, useEffect } from 'react';
import Slider from '../Slider/Slider'
import Cards from '../Cards/Cards'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Gallery from '../Gallery/Gallery'
import Modal from '../Modal/Modal';
import Price from '../Price/Price';
import Text from '../Text/Text';
import Details from '../Details/Details';
import Amenities from '../Amenities/Amenities';
import Plan from '../Plan/Plan';
import Location from '../Location/Location';


const Homepage = () => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    mobile: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  useEffect(() => {
    setShowModal(true);
  }, []);

  const hideModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';

    switch (fieldName) {
      case 'name':
        if (!value.trim()) {
          errorMessage = 'Name is required';
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          errorMessage = 'Name should contain only alphabets';
        }
        break;
      case 'email':
        if (!value.trim()) {
          errorMessage = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = 'Email is invalid';
        }
        break;
      case 'mobile':
        if (!value.trim()) {
          errorMessage = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(value)) {
          errorMessage = 'Mobile number should be 10 digits';
        }
        break;
      default:
        break;
    }

    setFormErrors({
      ...formErrors,
      [fieldName]: errorMessage
    });
  };

  const validateForm = () => {
    let valid = true;

    Object.keys(formValues).forEach((fieldName) => {
      validateField(fieldName, formValues[fieldName]);
      if (formErrors[fieldName]) {
        valid = false;
      }
    });

    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSubmitting(true);

    const templateParams = {
      to_name: formValues.name,
      email: formValues.email,
      name: formValues.name,
      mobile: formValues.mobile
    };

    emailjs
      .send('service_393qo3p', 'template_mvlq7bk', templateParams, 'J8emD_GPwwhZEPHBa')
      .then((response) => {
        setSubmitting(false);
        Swal.fire('Success', 'Email sent successfully!', 'success');
        hideModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setSubmissionError('Failed to send email. Please try again later.');
      });
  };



  return (
    <>
    <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">Get More Details</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
        <Slider/>
        <Text/>
        <Cards/>
        <Details/>
        <Gallery/>

        <Price/>
        <Amenities/>
        <Plan/>
        <Location/>

    </>
  )
}

export default Homepage