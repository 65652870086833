import React, { useState } from 'react';
import '../Cards/Cards.css';

const Cards = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [overlayImg, setOverlayImg] = useState(null); 

  const handleCardClick = (id, imgSrc) => {
    setSelectedCards((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((cardId) => cardId !== id)
        : [...prevSelected, id]
    );
    setOverlayImg(imgSrc); 
  };

  const handleCloseOverlay = () => {
    setOverlayImg(null); 
  };

  const cards = [
    {
      id: 1,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
    {
      id: 2,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
    {
      id: 3,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
    {
      id: 4,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
    {
      id: 5,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
    {
      id: 6,
      imgSrc: 'https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?t=st=1724305085~exp=1724308685~hmac=9afea3584a29ddb0989220961dc053c1607261238820503b8edfa142015c97a7&w=826',
      alt: 'Property',
      title: 'Sizes',
    },
  ];

  return (
    <div className='boddy'>
      <section>
        <h2>Our Layouts</h2>
        <div className="containerr">
          {cards.map((card) => (
            <div
              key={card.id}
              className={`card ${selectedCards.includes(card.id) ? 'active' : ''}`}
              onClick={() => handleCardClick(card.id, card.imgSrc)}
            >
              <div className="card-inner" style={{ '--clr': '#fff' }}>
                <div className="box">
                  <div className="imgBox">
                    <img src={card.imgSrc} alt={card.alt} />
                  </div>
                  <div className="icon">
                    <h3>{card.title}</h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Overlay for displaying the selected image */}
      {overlayImg && (
        <div className="overlay">
          <div className="overlay-content">
            <img src={overlayImg} alt="Overlay" />
            <button className="overlay-close" onClick={handleCloseOverlay}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
