import './App.css';
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Chatbox from './Components/Chatbox/Chatbox';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Homepage from './Components/Homepage/Homepage';
import Aboutus from './Components/Aboutus/Aboutus';
import Layouts from './Components/Layouts/Layouts';
import Pricelist from './Components/Pricelist/Pricelist';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/layouts" element={<Layouts />} />
        <Route path="/price" element={<Pricelist/>} />
      </Routes>
      <Chatbox />
      <Footer />
    </Router>
  );
}

export default App;
