import React from 'react'
import '../Layouts/Layouts.css'
import Cards from '../Cards/Cards'
import Plan from '../Plan/Plan'
const Layouts = () => {
  return (
    <>
<Cards/>
<Plan/>
    </>
  )
}

export default Layouts