import React, { useState } from 'react';
import '../Footer/Footer.css'; 
import Modal from "../Modal/Modal";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const currentYear = new Date().getFullYear();  // Dynamic year

  const showModalHandler = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    setTimeout(() => {
      setShowModal(false);
    }, 2000);
  };

  return (
    <>
      <footer className="footer">
        <ul className="footer-links">
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/layouts">Layouts</a></li>
          <li><a href="/price">Price List</a></li>
          <li><a href="#" onClick={() => showModalHandler('Get Our Brochure')}>Brochure</a></li>
          <li><a href="#" onClick={() => showModalHandler('Connect with Us')}>Contact</a></li>
        </ul>
        <hr className="footer-divider" />
        <div className="footer-rights">
          © {currentYear} RG Luxury Homes | Powered by <a href="https://infogainsoft.com/" className='link-info' target="_blank" rel="noopener noreferrer">Infogainsoft</a>
        </div>
        <div className="footer-powered">
         
        </div>
      </footer>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">{modalTitle}</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              rows="4"
              required
            />
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit">SUBMIT</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Footer;
