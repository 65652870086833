import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import '../Price/Price.css';

const Price = () => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({ name: '', email: '', mobile: '' });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const handleShowModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form validation and submission logic here
    // For now, we'll just simulate a successful submission
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setShowModal(false);
    }, 2000);
  };

  return (
    <div className="price-section">
      <div className="left-p">
        <h2 className="price-heading">Property Prices</h2>
        <p className="price-subheading">Find the perfect home for your needs</p>
      </div>
      <div className="right-p">
        <h3 className="price-list-heading">Price List</h3>
        <table className="price-table">
          <thead>
            <tr>
              <th>Types of Unit</th>
              <th>Built-Up Area</th>
              <th>Price List</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK+2T (TYPES-1)</td>
              <td>Built-Up 1175 SQ.FT.</td>
              <td>Cast - <span className="enquire-link" onClick={handleShowModal}>Enquire Now</span></td>
            </tr>
            <tr>
              <td>3 BHK+2T (TYPES-1)</td>
              <td>Built-Up 1175 SQ.FT.</td>
              <td>Cast - <span className="enquire-link" onClick={handleShowModal}>Enquire Now</span></td>
            </tr>
            <tr>
              <td>3 BHK+2T (TYPES-1)</td>
              <td>Built-Up 1175 SQ.FT.</td>
              <td>Cast - <span className="enquire-link" onClick={handleShowModal}>Enquire Now</span></td>
            </tr>
            <tr>
              <td>3 BHK+2T (TYPES-1)</td>
              <td>Built-Up 1175 SQ.FT.</td>
              <td>Cast - <span className="enquire-link" onClick={handleShowModal}>Enquire Now</span></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">Get More Details</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </div>
  );
};

export default Price;
