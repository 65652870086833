import React, { useState } from "react";
import "../Header/Header.css";
import Modal from "../Modal/Modal";
import { TiThMenu } from "react-icons/ti";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Get More Details');
  const [formValues, setFormValues] = useState({ name: '', email: '', mobile: '', query: '' });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    // Simulate form submission
    setTimeout(() => {
      setSubmitting(false);
      setShowModal(false);
    }, 2000);
  };

  const calladmin = () => {
    window.location.href = 'tel:+9100000000';
  };

  const showModalHandler = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <nav>
        <div className="wrapper-nav">
          <div className="logo">
            <a href="#">
              <img src="logo.png" className="logo-head" alt="logo" />
            </a>
          </div>
          <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
            <li>
              <Link to="/" onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/about" onClick={handleLinkClick}>About</Link>
            </li>
            <li>
              <Link to="/layouts" onClick={handleLinkClick}>Layouts</Link>
            </li>
            <li>
              <Link to="#" onClick={() => showModalHandler('Get Our Brochure')}>Brauchure</Link>
            </li>
            <li>
              <Link to="/price" onClick={handleLinkClick}>Price List</Link>
            </li>
            <li>
              <a href="#contact" onClick={() => showModalHandler('Connect with Us')}>Contact</a>
            </li>
            <li>
              <button className="butt-nav" onClick={calladmin}>000000000</button>
            </li>
          </ul>
          <div className="menu-btn" onClick={handleMenuToggle}>
            <TiThMenu className="menu-ic" />
          </div>
        </div>
      </nav>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">{modalTitle}</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </>
  );
};

export default Header;
